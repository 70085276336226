.user_list {
  list-style: none;
  margin: 4rem 0 0 0;
  padding: 1rem;

  .seller {
    font-size: 10pt !important;
    cursor: pointer;
    padding: 0.5rem;
    align-items: center;
    border-radius: 10px;
    transition: 0.3s;

    .seller_block {
      display: flex;
      font-size: 10pt !important;
      cursor: pointer;
      padding: 0.5rem;
      align-items: center;
      border-radius: 10px;
      // justify-content: space-between;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .chat {
      display: flex;
      font-size: 10pt !important;
      cursor: pointer;
      padding: 0.5rem !important;
      margin-left: 1.5rem !important;
      align-items: center;
      border-radius: 10px;
      box-shadow: none;
      transition: 0.3s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .info {
      margin-left: 10px;
      small {
        color: gray;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100px !important;
        display: block;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .load_more {
    text-align: center;
    color: #333;
    display: block;
    border-color: rgba(202, 15, 15, 1);
    border-width: 1px;
    border-style: solid;
    margin-top: 1px;
    font-size: 10pt !important;
    cursor: pointer;
    padding: 0.5rem;
    align-items: center;
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
      background-color: rgba(202, 15, 15, 1);
      color: #f3f3f3;
    }
  }
}

.user_chat_open {
  position: fixed;
  bottom: 1.5%;
  right: 3%;
  display: flex;
  z-index: 1;

  .user_chat {
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin-right: 1rem;
    background-color: #fff;
    height: 510px;
    width: 320px;
    position: relative;
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      padding: 0.7rem 0.5rem;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      align-items: center;

      .profile {
        display: flex;
        align-items: center;
        font-size: 10pt;
        span {
          font-weight: bold;
        }
      }

      .button_group {
        display: flex;
      }
    }

    .body {
      padding: 20px;
      height: 380px;
      position: relative;
      overflow-x: auto;

      .message {
        margin-bottom: 12px;

        .message_content {
          color: #594939;
          padding: 5px 10px;
          background-color: #fcf6ee;
          border-radius: 10px 10px 10px 0px;
          max-width: 80%;
          display: inline-block;
          text-align: left;
          font-size: 10pt;
        }

        i {
          margin-left: 3px;
          font-size: 5pt;
        }

        .card {
          background-color: #fcf6ee;
          color: #594939;
          max-width: 80%;
          display: inline-block;
          text-align: left;
          font-size: 10pt;
          border-radius: 10px 10px 10px 0px;
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            border-radius: 10px 0px 0px 0px;
            margin-bottom: 5px;
          }
        }
      }

      .message_self {
        text-align: right;
        margin-bottom: 12px;

        .message_content {
          border-radius: 10px 10px 0px 10px;
          background-color: #e2efff;
          color: #2a4e7f;
          max-width: 80%;
          display: inline-block;
          text-align: left;
          padding: 5px 10px;
          font-size: 10pt;
        }

        i {
          margin-left: 3px;
          font-size: 5pt;
        }

        .card {
          background-color: #e2efff;
          color: #2a4e7f;
          max-width: 80%;
          display: inline-block;
          text-align: left;
          font-size: 10pt;
          border-radius: 10px 10px 0px 10px;
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            border-radius: 10px 0px 0px 0px;
            margin-bottom: 5px;
          }
        }
      }
    }

    .footer {
      position: relative;
      // overflow: hidden;
      padding: 0.5rem;
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: rgba(0, 0, 0, 0.2);
      background-color: #fff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .chat_input {
        .chat_mention {
          top: -80%;
          left: 0;
          right: 0;
          position: absolute;
          background-color: #fff;
        }
      }

      input {
        border: none;

        &:focus {
          border: none;
        }
      }
    }
  }
}

.user_chat_active {
  position: fixed;
  bottom: 1%;
  right: 1%;
  z-index: 2;

  .user {
    position: relative;
  }

  .close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}

@keyframes scaler {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
